var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
function renderFromResponse(response) {
  if (response.error) {
    return;
  }
  $("#cart-detailed").html(response.table);
  $("#kevea-delivery").html(response.delivery_options);
  $("#kevea-payment").html(response.payment_methods);
  $("#kevea-summary").html(response.summary);
  $("#kevea-vouchers").html(response.vouchers);
  initHandlers();
  toggleLoader(false);
}
function toggleLoader(value) {
  $(".kv-loading").toggleClass("d-none", !value);
}
const handleRemoveFromCart = (removeUrl) => __async(this, null, function* () {
  toggleLoader(true);
  yield $.ajax({
    url: removeUrl,
    method: "GET"
  });
});
function openLoginModal() {
  $("#loginModal").modal("show");
}
$(function() {
  return __async(this, null, function* () {
    const deleteBtns = $(".remove-from-cart");
    deleteBtns.on("click", (event) => {
      const removeUrl = $(event.currentTarget).data("remove-url");
      event.currentTarget.setAttribute("disabled", "true");
      handleRemoveFromCart(removeUrl).then(() => {
        event.currentTarget.removeAttribute("disabled");
        window.location.reload();
      });
    });
    const loginModal = $("#loginModal");
    loginModal.on("hidden.bs.modal", () => {
      loginModal.find(".alert").remove();
    });
    if (loginModal.find(".alert").length) {
      loginModal.modal("show");
    }
    $("#openLoginModalButton").on("click", openLoginModal);
  });
});
function handleIsCompanyChange(fields) {
  var _a, _b;
  console.log(fields);
  const value = (_a = fields.isCompany) == null ? void 0 : _a.prop("checked");
  for (let field of FIELDS.company) {
    (_b = fields[field]) == null ? void 0 : _b.closest(".mb-3").toggle(!value);
  }
}
function displayInitialErrors(fields) {
  var _a, _b, _c, _d;
  const errors = $(".form-fields").data("errors");
  if (errors) {
    for (let field of ALL_FIELDS) {
      if (((_a = errors[field]) == null ? void 0 : _a.length) > 0) {
        (_b = fields[field]) == null ? void 0 : _b.addClass("is-invalid");
        (_d = (_c = fields[field]) == null ? void 0 : _c.nextAll(".form-text")) == null ? void 0 : _d.text(errors[field][0]);
      }
    }
  }
}
const FIELDS = {
  company: [
    "nip",
    "companyName"
  ],
  person: [],
  common: ["isCompany", "email", "phone", "address", "postcode", "city", "country", "acceptTerms", "acceptDataProcessing", "firstName", "lastName"],
  createAccount: ["password", "passwordConfirm"],
  delivery: ["delivery_firstName", "delivery_lastName", "delivery_address", "delivery_postcode", "delivery_city", "delivery_phone"]
};
const VALIDATION_MESSAGES = {
  email: "Nieprawid\u0142owy adres email",
  firstName: "Imi\u0119 musi sk\u0142ada\u0107 si\u0119 z liter",
  lastName: "Nazwisko musi sk\u0142ada\u0107 si\u0119 z liter",
  password: "Has\u0142o musi mie\u0107 co najmniej 8 znak\xF3w",
  passwordConfirm: "Has\u0142a musz\u0105 by\u0107 takie same",
  address: "Nieprawid\u0142owy adres",
  city: "Nieprawid\u0142owa nazwa miasta",
  phone: "Nieprawid\u0142owy numer telefonu",
  nip: "Nieprawid\u0142owy numer NIP",
  companyName: "Nieprawid\u0142owa nazwa firmy",
  required: "Pole jest wymagane"
};
const FIELD_VALIDATORS = {
  email: (value) => {
    return value.includes("@");
  },
  firstName: validate_isCustomerName,
  lastName: validate_isCustomerName,
  phone: validate_isPhoneNumber,
  address: validate_isAddress,
  city: validate_isCityName,
  nip: validate_isDniLite,
  companyName: validate_isGenericName,
  password: (value) => {
    return value.length >= 8;
  },
  passwordConfirm: (value) => {
    return value === $("#field-newpassword").val();
  }
};
const ALL_FIELDS = [...FIELDS.company, ...FIELDS.person, ...FIELDS.common, ...FIELDS.createAccount, ...FIELDS.delivery];
function validate_isCustomerName(s) {
  var reg = /^(?:[^0-9!<>,;?=+()\/\\@#"°*`\{\}_^$%:¤\[\]|\.。]|[\.。](?:\s|$))*$/;
  return reg.test(s);
}
function validate_isPhoneNumber(s) {
  var reg = /^[+0-9. ()-]+$/;
  return reg.test(s);
}
function validate_isAddress(s) {
  var reg = /^[^!<>?=+@{}_$%]+$/;
  return reg.test(s);
}
function validate_isCityName(s) {
  var reg = /^[^!<>;?=+@#"°{}_$%]+$/;
  return reg.test(s);
}
function validate_isDniLite(s) {
  var reg = /^[0-9a-z-.]{1,16}$/i;
  return reg.test(s);
}
function validate_isGenericName(s) {
  var reg = /^[^<>={}]+$/;
  return reg.test(s);
}
function validateField(field) {
  var _a, _b, _c, _d, _e;
  if (field.attr("type") === "checkbox") {
    if (!field.prop("checked")) {
      field.addClass("is-invalid");
      return false;
    }
  } else if (!field.val()) {
    (_b = field.nextAll(".form-text")) == null ? void 0 : _b.text((_a = VALIDATION_MESSAGES.required) != null ? _a : "");
    field.addClass("is-invalid");
    return false;
  }
  const key = field.attr("name");
  if (FIELD_VALIDATORS[key] && !((_c = FIELD_VALIDATORS[key]) == null ? void 0 : _c.call(FIELD_VALIDATORS, field.val()))) {
    (_e = field.nextAll(".form-text")) == null ? void 0 : _e.text((_d = VALIDATION_MESSAGES[key]) != null ? _d : "");
    field.addClass("is-invalid");
    return false;
  }
  field.removeClass("is-invalid");
  return true;
}
function validateForm(fields) {
  var _a, _b, _c;
  let isValid = true;
  const isCompany = !((_a = fields.isCompany) == null ? void 0 : _a.prop("checked"));
  const differentAddress = (_b = $("#differentAddress").prop("checked")) != null ? _b : false;
  const createAccount = (_c = $("#createAccount").prop("checked")) != null ? _c : false;
  if (isCompany) {
    for (let field of FIELDS.company) {
      isValid = validateField(fields[field]) && isValid;
    }
  } else {
    for (let field of FIELDS.person) {
      isValid = validateField(fields[field]) && isValid;
    }
  }
  for (let field of FIELDS.common) {
    isValid = validateField(fields[field]) && isValid;
  }
  if (createAccount) {
    for (let field of FIELDS.createAccount) {
      isValid = validateField(fields[field]) && isValid;
    }
  }
  if (differentAddress) {
    for (let field of FIELDS.delivery) {
      isValid = validateField(fields[field]) && isValid;
    }
  }
  return isValid;
}
function copyValuesToDelivery(fields) {
  var _a, _b, _c, _d, _e, _f;
  if ($("#differentAddress").prop("checked")) {
    if ((_a = fields.firstName) == null ? void 0 : _a.val())
      (_b = fields.delivery_firstName) == null ? void 0 : _b.val(fields.firstName.val());
    if ((_c = fields.lastName) == null ? void 0 : _c.val())
      (_d = fields.delivery_lastName) == null ? void 0 : _d.val(fields.lastName.val());
    if ((_e = fields.phone) == null ? void 0 : _e.val())
      (_f = fields.delivery_phone) == null ? void 0 : _f.val(fields.phone.val());
  }
}
function handleDeliveryOptionChange() {
  return __async(this, null, function* () {
    const deliveryOption = $("input[name=delivery_option]:checked");
    const deliveryOptionValue = deliveryOption.val();
    $(".carrier-extra-content").addClass("d-none");
    if (deliveryOption) {
      const extra = deliveryOption.parent().parent().next(".carrier-extra-content");
      if (extra) {
        extra.removeClass("d-none");
      }
    }
    const url = $("#kv-checkout-form").data("update-delivery-url");
    toggleLoader(true);
    const response = yield $.ajax({
      url,
      method: "POST",
      data: {
        ajax: 1,
        updateDeliveryOption: true,
        delivery_option: deliveryOptionValue
      }
    });
    renderFromResponse(response);
  });
}
function handleCountryChange(e) {
  return __async(this, null, function* () {
    const country = $("select[name=country]");
    const deliveryCountry = $("select[name=delivery_country]");
    const differentAddress = $("#differentAddress");
    const countryValue = country.val();
    const deliveryCountryValue = deliveryCountry.val();
    const url = $("#kv-checkout-form").data("update-delivery-url");
    toggleLoader(true);
    const response = yield $.ajax({
      url,
      method: "POST",
      data: {
        ajax: 1,
        updateCountry: true,
        detailsCountry: countryValue,
        deliveryCountry: deliveryCountryValue,
        differentAddress: differentAddress.prop("checked")
      }
    });
    renderFromResponse(response);
  });
}
function handleQuantityChange(event) {
  return __async(this, null, function* () {
    const { prestashop } = window;
    event.target.setAttribute("disabled", "true");
    const updateUrl = prestashop.urls.pages.order;
    let value = parseInt(event.target.value);
    const quantity_available = $(event.target).data("quantity-available");
    const id = $(event.target).data("product-id");
    if (value < 1) {
      value = 1;
      $(event.target).val(1);
    }
    toggleLoader(true);
    yield $.ajax({
      url: updateUrl,
      method: "POST",
      data: {
        ajax: 1,
        id_product: id,
        updateProductQuantity: true,
        quantity: value
      }
    }).then((response) => {
      renderFromResponse(response);
    });
  });
}
function handleProductDelete(event) {
  return __async(this, null, function* () {
    const { prestashop } = window;
    const removeUrl = prestashop.urls.pages.order;
    toggleLoader(true);
    yield $.ajax({
      url: removeUrl,
      method: "POST",
      data: {
        ajax: 1,
        id_product: $(event.target).data("product-id"),
        deleteProduct: true
      }
    }).then((response) => {
      renderFromResponse(response);
    });
  });
}
function addHandler(element, event, handler) {
  $(element).off(event, handler);
  $(element).on(event, handler);
}
function initHandlers() {
  addHandler($("#delete-voucher"), "click", handleVoucherDelete);
  addHandler($("#voucher-button"), "click", handleVoucherSubmit);
  addHandler($("#delete-all-products"), "click", handleAllProductsDelete);
  addHandler($("#delete-all-products-mobile"), "click", handleAllProductsDelete);
  addHandler($("select[name=country"), "change", handleCountryChange);
  addHandler($("select[name=delivery_country"), "change", handleCountryChange);
  addHandler($("#differentAddress"), "change", handleCountryChange);
  addHandler($("input[name=delivery_option"), "change", handleDeliveryOptionChange);
  $("input[name=product-quantity]").each((index, element) => {
    addHandler(element, "change", handleQuantityChange);
  });
  $("button.remove-from-cart").each((index, element) => {
    addHandler(element, "click", handleProductDelete);
  });
}
function validatePaczkomat() {
  const deliveryOptions = $(".delivery-item-title").toArray();
  const inpostOption = deliveryOptions.find((element) => {
    return $(element).text().toLowerCase().includes("inpost");
  });
  const selectedDeliveryOption = $("input[name=delivery_option]:checked").val();
  if (inpostOption && selectedDeliveryOption) {
    const inpostValue = $(".js-inpost-shipping-input").val();
    if (selectedDeliveryOption === $(inpostOption).siblings("input").val() && !inpostValue) {
      $(".inpost-shipping-button").addClass("is-invalid");
      $(".js-inpost-shipping-locker-errors").text("Musisz wybra\u0107 Paczkomat").addClass("text-danger");
      return false;
    }
  }
  $(".inpost-shipping-button").removeClass("is-invalid");
  $(".js-inpost-shipping-locker-errors").text("");
  return true;
}
function handleVoucherSubmit(e) {
  e.target.setAttribute("disabled", "true");
  const voucherInput = $("#voucher-input");
  const voucherCode = voucherInput.val();
  const url = window.prestashop.urls.pages.order;
  toggleLoader(true);
  $.ajax({
    url,
    method: "POST",
    data: {
      ajax: 1,
      addVoucher: true,
      voucher_code: voucherCode
    }
  }).then((response) => {
    e.target.removeAttribute("disabled");
    if (response.error) {
      voucherInput.addClass("is-invalid");
      voucherInput.parent().nextAll(".form-text").text(response.error);
      initHandlers();
      toggleLoader(false);
    } else {
      voucherInput.addClass("d-none");
      renderFromResponse(response);
    }
  });
}
function handleAllProductsDelete(e) {
  const url = window.prestashop.urls.pages.order;
  toggleLoader(true);
  $.ajax({
    url,
    method: "POST",
    data: {
      ajax: 1,
      deleteAllProducts: true
    }
  }).then((response) => {
    window.location.pathname = "/";
  });
}
function handleVoucherDelete(e) {
  const idCartRule = $(e.target).data("id-cart-rule");
  const url = window.prestashop.urls.pages.order;
  toggleLoader(true);
  $.ajax({
    url,
    method: "POST",
    data: {
      ajax: 1,
      deleteVoucher: true,
      id_cart_rule: idCartRule
    }
  }).then((response) => {
    renderFromResponse(response);
  });
}
const initCheckout = () => {
  var _a, _b;
  const { prestashop } = window;
  const { Theme: { selectors, events } } = window;
  const form = $("#kv-checkout-form");
  const submitButton = form.find("button[type=submit]");
  initHandlers();
  submitButton.removeAttr("disabled");
  let wasSubmitted = false;
  form.on("submit", (event) => {
    wasSubmitted = true;
    let isValid = true;
    if (!validatePaczkomat())
      isValid = false;
    if (!validateForm(fields))
      isValid = false;
    if (!isValid) {
      event.preventDefault();
    }
  });
  const fields = {};
  for (let field of ALL_FIELDS) {
    fields[field] = form.find(`[name=${field}]`);
    (_a = fields[field]) == null ? void 0 : _a.on("input", () => {
      if (wasSubmitted) {
        validateField(fields[field]);
      }
    });
  }
  displayInitialErrors(fields);
  handleIsCompanyChange(fields);
  (_b = fields.isCompany) == null ? void 0 : _b.on("change", () => {
    handleIsCompanyChange(fields);
  });
  const createAccountCheckbox = $("#createAccount");
  const differentAddressCheckbox = $("#differentAddress");
  createAccountCheckbox.on("change", () => {
    const value = createAccountCheckbox.prop("checked");
    $(".create-account-section").toggleClass("d-none", !value);
  });
  differentAddressCheckbox.on("change", () => {
    const value = differentAddressCheckbox.prop("checked");
    $(".different-address-section").toggleClass("d-none", !value);
    copyValuesToDelivery(fields);
  });
  if (differentAddressCheckbox.prop("checked")) {
    $(".different-address-section").removeClass("d-none");
  }
  toggleLoader(false);
};
export default initCheckout;
