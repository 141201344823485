import useQuantityInput from "@js/components/useQuantityInput";
export const parseSearchUrl = function(event) {
  if (event.target.dataset.searchUrl !== void 0) {
    return event.target.dataset.searchUrl;
  }
  if ($(event.target).parent()[0].dataset.searchUrl === void 0) {
    throw new Error("Can not parse search URL");
  }
  return $(event.target).parent()[0].dataset.searchUrl;
};
export function updateProductListDOM(data) {
  const { Theme } = window;
  $(Theme.selectors.listing.searchFilters).replaceWith(data.rendered_facets);
  $(Theme.selectors.listing.activeSearchFilters).replaceWith(data.rendered_active_filters);
  $(Theme.selectors.listing.listTop).replaceWith(data.rendered_products_top);
  const renderedProducts = $(data.rendered_products);
  const productSelectors = $(Theme.selectors.listing.product, renderedProducts);
  const firstProductClasses = $(Theme.selectors.listing.product).first().attr("class");
  if (productSelectors.length > 0 && firstProductClasses) {
    productSelectors.removeClass().addClass(firstProductClasses);
  }
  $(Theme.selectors.listing.list).replaceWith(renderedProducts);
  $(Theme.selectors.listing.listBottom).replaceWith(data.rendered_products_bottom);
  if (data.rendered_products_header) {
    $(Theme.selectors.listing.listHeader).replaceWith(data.rendered_products_header);
  }
}
export default () => {
  const { prestashop } = window;
  const { Theme } = window;
  const { events } = Theme;
  $("body").on("change", `${Theme.selectors.listing.searchFilters} input[data-search-url]`, (event) => {
    prestashop.emit(events.updateFacets, parseSearchUrl(event));
  });
  $("body").on("click", Theme.selectors.listing.searchFiltersClearAll, (event) => {
    prestashop.emit(events.updateFacets, parseSearchUrl(event));
  });
  $("body").on("click", Theme.selectors.listing.searchLink, (event) => {
    var _a, _b, _c;
    event.preventDefault();
    prestashop.emit(events.updateFacets, (_c = (_b = (_a = $(event.target)) == null ? void 0 : _a.closest("a")) == null ? void 0 : _b.get(0)) == null ? void 0 : _c.getAttribute("href"));
  });
  $("body").on("click", Theme.selectors.listing.pagerLink, (event) => {
    var _a, _b, _c, _d;
    event.preventDefault();
    (_a = document.querySelector(Theme.selectors.listing.listTop)) == null ? void 0 : _a.scrollIntoView({ block: "start", behavior: "auto" });
    prestashop.emit(events.updateFacets, (_d = (_c = (_b = $(event.target)) == null ? void 0 : _b.closest("a")) == null ? void 0 : _c.get(0)) == null ? void 0 : _d.getAttribute("href"));
  });
  if ($(Theme.selectors.listing.list).length) {
    window.addEventListener("popstate", (e) => {
      const { state } = e;
      window.location.href = state && state.current_url ? state.current_url : history;
    });
  }
  $("body").on("change", `${Theme.selectors.listing.searchFilters} select`, (event) => {
    const form = $(event.target).closest("form");
    prestashop.emit(events.updateFacets, `?${form.serialize()}`);
  });
  prestashop.on(events.updateProductList, (data) => {
    updateProductListDOM(data);
    useQuantityInput();
  });
};
